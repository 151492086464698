import { SettingOutlined } from '@ant-design/icons';
import { Form, Input, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import Spinner from '../../components/spinner';
import { createApiToken, getApiTokens } from '../../redux/actions/api-token';
import DevicesPage from '../devices';
import SerialsPage from '../serials';

export const AutomationPage = () => {
    const dispatch = useDispatch();
    // Seleccionamos el estado de los tokens y el estado de carga (isLoading)
    const apiTokens = useSelector((state) => state.token.apiTokens);
    const isLoading = useSelector((state) => state.token.loading);
    // Usamos useMemo para memorizar el valor de dataSource
    const dataSource = useMemo(() => apiTokens || [], [apiTokens]);

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getApiTokens());
    }, [dispatch]);

    useEffect(() => {
        if (dataSource) {
            // Actualiza los valores del formulario cuando 'dataSource' cambie
            form.setFieldsValue(dataSource);
        }
    }, [dataSource, form]);

    const openSettingsModal = () => {
        setShowSettingsModal(true);
    };

    const closeSettingsModal = () => {
        setShowSettingsModal(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log('Valores del formulario de configuración:', values);
            dispatch(createApiToken(values, () => {
                console.log('API Token creado con éxito');
                closeSettingsModal();
            }));
        }).catch((error) => {
            console.error('Error en la validación:', error);
        });
    };

    const renderSettingsForm = () => {
        console.log('El valor del inicio en renderSettings', dataSource);
        return (
            <Form form={form} layout="vertical" initialValues={dataSource}>
                <Form.Item
                    name="urlToken"
                    label="URL Token"
                    rules={[
                        { required: true, message: 'Por favor ingrese la URL del Token' },
                        { type: 'url', message: 'Por favor ingrese una URL válida' }
                    ]}
                    initialValue={dataSource.urlToken}
                >
                    <Input placeholder="Ingrese la URL del Token" />
                </Form.Item>
                <Form.Item
                    name="urlComando"
                    label="URL Comando"
                    rules={[
                        { required: true, message: 'Por favor ingrese la URL del Comando' },
                        { type: 'url', message: 'Por favor ingrese una URL válida' }
                    ]}
                    initialValue={dataSource.urlComando}
                >
                    <Input placeholder="Ingrese la URL del Comando" />
                </Form.Item>
                <Form.Item
                    name="urlStatus"
                    label="URL Status"
                    rules={[
                        { required: true, message: 'Por favor ingrese la URL del Status' },
                        { type: 'url', message: 'Por favor ingrese una URL válida' }
                    ]}
                    initialValue={dataSource.urlStatus}
                >
                    <Input placeholder="Ingrese la URL del Status" />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        text="Guardar"
                    />
                </Form.Item>
            </Form>
        );
    };

    return (
        <div style={{ padding: '20px' }}>
            {isLoading ? (<Spinner />) : (
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '20px'
                }}>
                    <SettingOutlined
                        style={{
                            fontSize: '32px',
                            color: '#306A59',
                            cursor: 'pointer'
                        }}
                        onClick={openSettingsModal}
                    />
                </div>
            )}

            <SerialsPage />
            <DevicesPage />

            <Modal
                title="Configuración"
                open={showSettingsModal}
                onCancel={closeSettingsModal}
                footer={null}
            >
                {renderSettingsForm()}
            </Modal>
        </div>
    );
};

export default AutomationPage;
