import dayjs from 'dayjs';
// Convertir las horas a minutos para facilitar la comparación
export const convertToMinutes = (time) => {
    if (time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }
    return;
};

export const isTimeRangeOverlapping = (newRange, existingRanges) => {
    const { horaInicio: newStart, horaFin: newEnd } = newRange;
    const isInvalid = isInvalidTimeRange(newStart, newEnd);
    if (isInvalid.isInvalid) {
        return true; // Hay solapamiento
    }
    const newStartMinutes = convertToMinutes(newStart);
    const newEndMinutes = convertToMinutes(newEnd);
    // Verificar solapamiento con cada rango existente
    for (const range of existingRanges) {
        const { horaInicio: existingStart, horaFin: existingEnd } = range;

        if (isInvalidTimeRange(existingStart, existingEnd).isInvalid) {
            return true; // Existe algoo mal en el rango existente
        }

        const existingStartMinutes = convertToMinutes(existingStart);
        const existingEndMinutes = convertToMinutes(existingEnd);

        // Comprobar si hay solapamiento
        if (
            (newStartMinutes < existingEndMinutes && newEndMinutes > existingStartMinutes) || // Solapamiento general
            (newStartMinutes === existingEndMinutes || newEndMinutes === existingStartMinutes) // Se tocan exactamente
        ) {
            return true; // Hay solapamiento
        }
    }

    return false; // No hay solapamiento
};

export const intervalosFormateados = (horarios) =>
    horarios?.length
        ? horarios.map((intervalo) => ({
            horaInicio: dayjs(intervalo.horaInicio * 1000).format('HH:mm:ss'),
            horaFin: dayjs(intervalo.horaFin * 1000).format('HH:mm:ss'),
        }))
        : [
            {
                horaInicio: null, // Default para casos iniciales
                horaFin: null,
            },
        ];

export function isInvalidTimeRange(horaInicio, horaFin) {
    if (!horaInicio || !horaFin) {
        return { isInvalid: false, reason: null }; // Si falta alguno de los valores, no se realiza la validación
    }

    const inicio = dayjs(horaInicio, 'HH:mm:ss');
    const fin = dayjs(horaFin, 'HH:mm:ss');

    if (!inicio.isValid() || !fin.isValid()) {
        return { isInvalid: true, reason: 'Formato de hora inválido' };
    }

    if (inicio.isAfter(fin)) {
        return { isInvalid: true, reason: 'La hora de inicio es mayor que la hora de fin' };
    }

    if (fin.isBefore(inicio)) {
        return { isInvalid: true, reason: 'La hora de fin es menor que la hora de inicio' };
    }

    return { isInvalid: false, reason: null }; // Si todo es válido
}

export const convertToTimestamp = (time) => {
    if (!time) return null; // Manejar el caso de tiempo no definido
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0); // Establecer horas, minutos, segundos y milisegundos
    return date.getTime(); // Retornar el timestamp en milisegundos
};