/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import {
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import {
  Col,
  Form,
  Modal,
  Pagination,
  Row,
  Select,
  Space
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import Icon from '../../components/icon';
import Input from '../../components/input';
import Spinner from '../../components/spinner';
import Table from '../../components/table';
import { getAllEstablishments } from '../../redux/actions/establishment';
import { createSerial, deleteSerial, getSerials, updateSerial } from '../../redux/actions/serial';

const { Item } = Form;
const { Option } = Select;

const columns = [
  {
    title: 'Nombre',
    dataIndex: 'nombre',
    key: 'nombre'
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Establecimiento',
    dataIndex: 'establishment',
    key: 'establishment'
  }
];

const SerialsPage = () => {
  const dispatch = useDispatch();
  const rol = localStorage.getItem('rol');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [selectedSerial, setSelectedSerial] = useState(null);
  const [form] = Form.useForm();

  // Estado de Redux
  const state = useSelector((state) => state.serial);
  const { establishments } = useSelector((state) => state.establishmentReducer);
  const dataSource = useMemo(() => state?.serials?.data || [], [state]);
  const isLoading = useMemo(() => state?.isLoading || false, [state]);

  // Transformamos los datos
  const transformedData = useMemo(() => dataSource?.map(serial => ({
    key: serial._id,
    nombre: serial.nombre,
    id: serial.serial,
    establishment: serial.establecimiento.nombre,
    _id: serial._id,
    establecimiento: serial.establecimiento._id
  })) || [], [dataSource]);

  // Calcular datos paginados
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return transformedData.slice(startIndex, endIndex);
  }, [transformedData, currentPage]);

  // Manejador de cambio de página
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    dispatch(getSerials());
    dispatch(getAllEstablishments());
  }, [dispatch]);

  const openModal = () => {
    setShowModalNew(true);
  };

  const closeModal = () => {
    setShowModalNew(false);
    setShowModalEdit(false);
    setSelectedSerial(null);
    form.resetFields();
  };

  const handleDelete = (id) => {
    dispatch(deleteSerial(id, () => {
      dispatch(getSerials());
    }));
  };

  const handleEdit = (record) => {
    const editConfigs = {
      serial: {
        formData: {
          nombre: record.nombre,
          id: record.id,
          establishment: record.establecimiento
        }
      },
      device: {
        formData: {
          nombre: record.nombre,
          id: record.id,
          establishment: record.establecimiento,
          configuracion: record.configuracion || {}
        }
      },
      url: {
        formData: {
          nombre: record.nombre,
          id: record.id,
          establishment: record.establecimiento,
          url: record.url
        }
      }
    };

    try {
      const config = editConfigs[record.tipo] || editConfigs.serial;
      setSelectedSerial(record);
      form.setFieldsValue(config.formData);
      setShowModalEdit(true);
    } catch (error) {
      console.error('Error al cargar datos para editar:', error);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const data = {
        nombre: values.nombre,
        serial: values.id,
        establecimiento: values.establishment
      };

      if (!/^[0-9a-fA-F]{24}$/.test(data.establecimiento)) {
        throw new Error('ID de establecimiento inválido');
      }

      if (selectedSerial) {
        dispatch(updateSerial(selectedSerial._id, data, () => {
          closeModal();
          dispatch(getSerials());
        }));
      } else {
        dispatch(createSerial(data, () => {
          closeModal();
          dispatch(getSerials());
        }));
      }
    });
  };

  const actionsColumn = (record) => (
    <Space size="middle">
      <Button
        type="primary"
        icon={<EditOutlined />}
        color="transparent"
        text="Editar"
        onClick={() => handleEdit(record)}
      />
      <Button
        type="primary"
        icon={<DeleteOutlined />}
        color="transparent"
        text="Eliminar"
        onClick={() => handleDelete(record._id)}
      />
    </Space>
  );

  const renderForm = () => (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Item
            name="nombre"
            label="Nombre"
            rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="id"
            label="Serial"
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Item
        name="establishment"
        label="Establecimiento"
        rules={[{ required: true, message: 'Por favor seleccione el establecimiento' }]}
      >
        <Select placeholder="Seleccione un establecimiento">
          {establishments?.map(est => (
            <Option key={est._id} value={est._id}>
              {est.nombre}
            </Option>
          ))}
        </Select>
      </Item>
      <Item style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button type="primary" onClick={handleSubmit} text="Guardar" />
      </Item>
    </Form>
  );

  return (
    <div className="container-page__box">
      <div className="container-page__header" style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 28px'
      }}>
        <div className="container-page__title">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="Home" size={64} />
            <div style={{
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '64px'
            }}>
              <h1 style={{
                margin: 0,
                fontSize: '24px',
                color: '#306A59'
              }}>
                Seriales
              </h1>
              <div style={{
                width: '50%',
                height: '8px',
                backgroundColor: '#306A59'
              }} />
            </div>
          </div>
        </div>
        <div className="container-page__actions">
          {rol === 'superadmin' && (
            <Button
              text={(
                <>
                  <span style={{ textDecoration: 'underline' }}>Agregar serial</span>
                  {' +'}
                </>
              )}
              color="transparent"
              onClick={openModal}
            />
          )}
        </div>
      </div>
      <div className="container-page__table" style={{ padding: '0 28px' }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={paginatedData}
              actionsColumn={actionsColumn}
              actions={rol === 'superadmin'}
            />
            <div className="container-page__pagination">
              <Pagination
                current={currentPage}
                onChange={handlePageChange}
                total={transformedData.length}
                pageSize={pageSize}
              />
            </div>
          </>
        )}
      </div>
      <Modal
        title="Crear nuevo Serial"
        open={showModalNew}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
      >
        {renderForm()}
      </Modal>
      <Modal
        title="Editar Serial"
        open={showModalEdit}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
      >
        {renderForm()}
      </Modal>
    </div>
  );
};

export default SerialsPage;
