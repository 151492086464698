import { api } from '../../service/api';
import { ESTABLISHMENT_API_PATH, SERIAL_PATH } from '../../service/path';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

// Action Types
export const GET_SERIALS_REQUEST = 'GET_SERIALS_REQUEST';
export const GET_SERIALS_SUCCESS = 'GET_SERIALS_SUCCESS';
export const GET_SERIALS_FAILURE = 'GET_SERIALS_FAILURE';

export const CREATE_SERIAL = 'CREATE_SERIAL';
export const UPDATE_SERIAL = 'UPDATE_SERIAL';
export const DELETE_SERIAL = 'DELETE_SERIAL';

// Action Creators
export const getSerials = () => (dispatch) => {
    dispatch({ type: GET_SERIALS_REQUEST });

    return api.get(SERIAL_PATH)
        .then(response => {
            dispatch({
                type: GET_SERIALS_SUCCESS,
                payload: {
                    data: response.data,
                    total: response.data.length
                }
            });
        })
        .catch(error => {
            dispatch({
                type: GET_SERIALS_FAILURE,
                payload: error.message
            });
        });
};

export const createSerial = (data, callback) => (dispatch) => {
    return api.post(SERIAL_PATH, data)
        .then(response => {
            if (response.data) {
                dispatch({
                    type: CREATE_SERIAL,
                    payload: response.data
                });
                showSuccessMessage('El serial fue creado correctamente', dispatch);
                if (callback) callback();
            }
        })
        .catch(error => {
            showErrorMessage(error.response?.data?.message || 'Error al crear serial', dispatch);
        });
};

export const updateSerial = (id, data, callback) => (dispatch) => {
    return api.put(`${SERIAL_PATH}/${id}`, data)
        .then(response => {
            dispatch({
                type: UPDATE_SERIAL,
                payload: response.data
            });
            showSuccessMessage('El serial fue actualizado correctamente', dispatch);
            if (callback) callback();
        })
        .catch(error => {
            showErrorMessage(error.response?.data?.message || 'Error al actualizar serial', dispatch);
        });
};

export const deleteSerial = (id, callback) => (dispatch) => {
    return api.delete(`${SERIAL_PATH}/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_SERIAL,
                payload: id
            });
            showSuccessMessage('El serial fue eliminado correctamente', dispatch);
            if (callback) callback();
        })
        .catch(error => {
            showErrorMessage(error.response?.data?.message || 'Error al eliminar serial', dispatch);
        });
};

export const getAllSerialsEstablishment = (establishmentId) => (dispatch) => dispatch({
    type: GET_SERIALS_REQUEST,
    promise: api.get(`${ESTABLISHMENT_API_PATH}/${establishmentId}/serials`),
    meta: {
        onSuccess: (response) => {
            console.log('Respuesta recibida:', response.data);
            dispatch({
                type: GET_SERIALS_SUCCESS,
                payload: {
                    data: response.data,
                    total: response.data.length
                }
            });
        },
        onFailure: (error) => {
            console.log('Error:', error);
            dispatch({
                type: GET_SERIALS_FAILURE,
                payload: error
            });
        }
    }
});