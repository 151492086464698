import { api } from '../../service/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

export const CREATE_AUTO_TIMER = 'CREATE_AUTO_TIMER';

export const CREATE_AUTO_THRESHOLD = 'CREATE_AUTO_THRESHOLD';

export const createOrUpdateAutoTimer = (data, callback) => (dispatch) => {
    console.log('Data enviado:', data);
    return api.post('/serial-listener/automation', data)
        .then(response => {
            dispatch({
                type: CREATE_AUTO_TIMER,
                payload: response.data
            });
            showSuccessMessage('El intervalo de tiempo fue creado o actualizado correctamente', dispatch);
            if (callback) callback();
        })
        .catch(error => {
            showErrorMessage(`Error en el intervalo de tiempo ${error}`, dispatch);
        });
};

export const createOrUpdateAutoThreshold = (data, callback) => (dispatch) => {
    return api.post('/serial-listener/threshold-control', data)
        .then(response => {
            dispatch({
                type: CREATE_AUTO_THRESHOLD,
                payload: response.data
            });
            showSuccessMessage('El intervalo por umbral se ha creado correctamente o actualizado correctamente', dispatch);
            if (callback) callback();
        })
        .catch(error => {
            showErrorMessage(`Error en el intervalo de tiempo ${error}`, dispatch);
        });
};