
export const deviceSelectionIcon = (typeDevice) => {
  switch (typeDevice) {
    case 'Temperatura':
      return 'AirConditioning';
    case 'Luz':
      return 'Focus';
    case 'Humedad':
      return 'Humidity';
    case 'Virtual':
      return 'Caloventor';
    default:
      return 'Default';
  }
};
