/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';
import LayoutContainer from '../components/layout';
import AutomationPage from '../pages/automation';
import CampaignDetail from '../pages/campaign-detail';
import CreateCropProfile from '../pages/crop-profile-create';
import EditCropProfile from '../pages/crop-profile-edit';
import CropProfile from '../pages/crop-profiles';
import DevicesPage from '../pages/devices';
import DetailEstablishment from '../pages/establishment-detail';
import Establishment from '../pages/establishments';
import Home from '../pages/home/ index';
import Login from '../pages/login';
import Reports from '../pages/reports';
import Sensors from '../pages/sensors';
import SerialsPage from '../pages/serials';
import Users from '../pages/users';
import {
  AUTOMATION_PATH,
  CAMPAING_DETAILS_PATH,
  CROP_PROFILE_NEW_PATH,
  CROP_PROFILE_PATH,
  DEVICES_PATH,
  ESTABLISHMENTS_PATH,
  LOGIN_PATH,
  REPORTS_PATH,
  ROOT_PAGE_PATH,
  SENSORES_PATH,
  SERIALS_PATH,
  USERS_PATH
} from './path';

const routes = [
  {
    path: LOGIN_PATH,
    component: <Login />
  },
  {
    path: ROOT_PAGE_PATH,
    component: <Home />
  },
  {
    path: USERS_PATH,
    component: <Users />
  },
  {
    path: ESTABLISHMENTS_PATH,
    component: <Establishment />
  },
  {
    path: `${ESTABLISHMENTS_PATH}/:id`,
    component: <DetailEstablishment />
  },
  {
    path: CROP_PROFILE_PATH,
    component: <CropProfile />
  },
  {
    path: SENSORES_PATH,
    component: <Sensors />
  },
  {
    path: `${CROP_PROFILE_PATH}/:id`,
    component: <EditCropProfile />
  },
  {
    path: CROP_PROFILE_NEW_PATH,
    component: <CreateCropProfile />
  },
  {
    path: `${REPORTS_PATH}/:id`,
    component: <Reports />
  },
  {
    path: `${CAMPAING_DETAILS_PATH}/:id`,
    component: <CampaignDetail />
  },
  {
    path: AUTOMATION_PATH,
    component: <AutomationPage />
  },
  {
    path: SERIALS_PATH,
    component: <SerialsPage />
  },
  {
    path: DEVICES_PATH,
    component: <DevicesPage />
  }
];
const getRoutes = () => routes.map(({ path, component }) => (
  <Route path={path} element={component} key={path} />
));
const RouteApp = () => (
  <BrowserRouter>
    <LayoutContainer>
      <Routes>
        {getRoutes()}
        <Route path="*" element={<Navigate to={ROOT_PAGE_PATH} />} />
      </Routes>
    </LayoutContainer>
  </BrowserRouter>
);

export default RouteApp;
