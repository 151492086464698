import { api } from '../../service/api';
import { API_CONFIG_PATH } from '../../service/path';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

// Action Types
export const GET_API_TOKEN_REQUEST = 'GET_API_TOKEN_REQUEST';
export const GET_API_TOKEN_SUCCESS = 'GET_API_TOKEN_SUCCESS';
export const GET_API_TOKEN_FAILURE = 'GET_API_TOKEN_FAILURE';

export const CREATE_API_TOKEN = 'CREATE_API_TOKEN';

// Action Creators
export const getApiTokens = () => (dispatch) => {
    dispatch({ type: GET_API_TOKEN_REQUEST });

    return api.get(API_CONFIG_PATH)
        .then(response => {
            dispatch({
                type: GET_API_TOKEN_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_API_TOKEN_FAILURE,
                payload: error.message
            });
        });
};

export const createApiToken = (data, callback) => (dispatch) => {
    return api.post(API_CONFIG_PATH, data)
        .then(response => {
            dispatch({
                type: CREATE_API_TOKEN,
                payload: response.data
            });
            showSuccessMessage('La configuración API fue creada correctamente', dispatch);
            if (callback) callback();
        })
        .catch(error => {
            showErrorMessage(error.response?.data?.message || 'Error al crear configuración API', dispatch);
        });
};
