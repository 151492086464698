import {
    GET_API_TOKEN_REQUEST,
    GET_API_TOKEN_SUCCESS,
    GET_API_TOKEN_FAILURE,
    CREATE_API_TOKEN
} from '../actions/api-token';
const initialState = {
    apiTokens: {},
    loading: false,
    error: null
};
const apiTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_API_TOKEN_REQUEST:
            return { ...state, loading: true };
        case GET_API_TOKEN_SUCCESS:
            return { ...state, loading: false, apiTokens: action.payload };
        case GET_API_TOKEN_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case CREATE_API_TOKEN:
            return { ...state, apiTokens: action.payload };
        default:
            return state;
    }
};

export default apiTokenReducer;
