import { CREATE_AUTO_TIMER, CREATE_AUTO_THRESHOLD } from '../actions/serialListener';

const initialState = {
    autoTimer: null,
    autoThreshold: null,
    loading: false,
    error: null,
};

const serialListenerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_AUTO_TIMER:
            return {
                ...state,
                autoTimer: action.payload,
                loading: false,
                error: null,
            };

        case CREATE_AUTO_THRESHOLD:
            return {
                ...state,
                autoThreshold: action.payload,
                loading: false,
                error: null, // Limpiar el error
            };

        case 'LOADING':
            return {
                ...state,
                loading: true,
            };

        case 'ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default serialListenerReducer;
