export const LOGIN_PATH = '/login';

export const ROOT_PAGE_PATH = '/';

export const ESTABLISHMENTS_PATH = '/establecimientos';

export const CROP_PROFILE_PATH = '/perfil-cultivo';

export const CROP_PROFILE_NEW_PATH = '/perfil-cultivo/nuevo';

export const SENSORES_PATH = '/sensores';

export const USERS_PATH = '/usuarios';

export const REPORTS_PATH = '/reportes';

export const CAMPAING_DETAILS_PATH = '/detalle-campaña';

export const SERIALS_PATH = '/seriales';

export const DEVICES_PATH = '/dispositivos';

export const AUTOMATION_PATH = '/automatizacion';
