/* eslint-disable import/no-cycle */
import { api } from '../../service/api';
import { HOME_API_PATH } from '../../service/path';
import { GET_ESTABLISHMENT_PANEL } from './types';
// import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

export const getAllEstablishmentsPanel = (callback) => (dispatch) => dispatch({
  type: GET_ESTABLISHMENT_PANEL,
  promise: api.get(HOME_API_PATH),
  meta: {
    onSuccess: () => {
      callback();
    },
  }
});

export const getAllEstablishmentsPanelNotLoading = () => (dispatch) => dispatch({
  type: GET_ESTABLISHMENT_PANEL,
  promise: api.get(HOME_API_PATH),
});
