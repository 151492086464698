import {
    CREATE_DEVICE,
    DELETE_DEVICE,
    GET_DEVICES_BY_ESTABLISHMENT_FAILURE,
    GET_DEVICES_BY_ESTABLISHMENT_REQUEST,
    GET_DEVICES_BY_ESTABLISHMENT_SUCCESS,
    GET_DEVICES_FAILURE,
    GET_DEVICES_REQUEST,
    GET_DEVICES_SUCCESS,
    GET_TYPES_DEVICES_FAILURE,
    GET_TYPES_DEVICES_REQUEST,
    GET_TYPES_DEVICES_SUCCESS,
    UPDATE_DEVICE
} from '../actions/device';

const initialState = {
    devices: {
        data: [],
        total: 0
    },
    deviceTypes: [],
    isLoading: false,
    error: null
};

const deviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DEVICES_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case GET_DEVICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                devices: {
                    data: action.payload.data || action.payload,
                    total: action.payload.total || action.payload.length
                }
            };
        case GET_DEVICES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case CREATE_DEVICE:
            if (action.error) {
                return {
                    ...state,
                    error: action.payload
                };
            }
            return {
                ...state,
                devices: {
                    data: [...state.devices.data, action.payload],
                    total: state.devices.total + 1
                }
            };
        case UPDATE_DEVICE:
            if (action.error) {
                return {
                    ...state,
                    error: action.payload
                };
            }
            return {
                ...state,
                devices: {
                    data: state.devices.data.map(device =>
                        device._id === action.payload._id ? action.payload : device
                    ),
                    total: state.devices.total
                }
            };
        case DELETE_DEVICE:
            if (action.error) {
                return {
                    ...state,
                    error: action.payload
                };
            }
            return {
                ...state,
                devices: {
                    data: state.devices.data.filter(device => device._id !== action.payload),
                    total: state.devices.total - 1
                }
            };
        case GET_TYPES_DEVICES_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case GET_TYPES_DEVICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                deviceTypes: action.payload.data || action.payload
            };
        case GET_TYPES_DEVICES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case GET_DEVICES_BY_ESTABLISHMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case GET_DEVICES_BY_ESTABLISHMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                devices: {
                    data: action.payload.data || action.payload,
                    total: action.payload.total || action.payload.length
                }
            };
        case GET_DEVICES_BY_ESTABLISHMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default deviceReducer;