import PropTypes from 'prop-types';
import React from 'react';
import CardInfoHeader from '../../pages/home/card-info-header';
import Modulos from '../card-info/modulos';

const CardDesktop = ({
  title, longitud, latitud, modules, wifi, idEstablishment, handleBackListener, handleModuleTap
}) => (
  <div className="card-home-desktop">
    <CardInfoHeader
      title={title}
      longitud={longitud}
      latitud={latitud}
    />
    <div className="card-home-desktop__modulos flex">
      <Modulos
        modules={modules}
        wifi={wifi}
        idEstablishment={idEstablishment}
        handleBackListener={handleBackListener}
        handleModuleTap={handleModuleTap}
      />
    </div>

  </div>
);
CardDesktop.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape({})),
  wifi: PropTypes.string,
  idEstablishment: PropTypes.string,
  title: PropTypes.string.isRequired,
  latitud: PropTypes.number.isRequired,
  longitud: PropTypes.number.isRequired,
  handleBackListener: PropTypes.func,
  handleModuleTap: PropTypes.func
};
CardDesktop.defaultProps = {
  modules: [],
  wifi: '',
  idEstablishment: '',
  handleBackListener: () => { },
  handleModuleTap: () => { }
};

export default CardDesktop;
